<template>
    <wait-for-resource :resource="team">
        <team-sheet
            :league="league"
            :team="team"
        />
    </wait-for-resource>
</template>

<script>
import {Team} from '@/models/Team';
import {League} from '@/models/League';
import TeamSheet from '@/components/leagues/teams/TeamSheet';

export default {
    name: 'LeaguesTeamShow',
    components: {TeamSheet},
    props: {
        league: {
            required: true,
            type: League,
        },
        teamSlug: {
            required: true,
            type: String,
        },
    },
    data() {
        return {
            team: new Team({slug: this.teamSlug}),
        };
    },
    async created() {
        try {
            await this.team.fetch();
        } catch (error) {}
    },
};
</script>
